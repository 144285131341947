import GoogleDBM from 'core/components/GoogleDBM';

const dbmParams = {
  configId: '8739587',
  countingMethod: 'standard',
  gstParamsCommon: {
    type: 'invmedia',
    cat: 'ezvgsnmt',
    u1: 'wmj.ru',
    ord: '1',
    dc_lat: null,
    dc_rdid: null,
    tag_for_child_directed_treatment: null,
    tfua: null,
    npa: null,
  },
  gstParamsScript: {
    allow_custom_scripts: true,
  },
  rubricFilters: [
    'stil-zhizni/kino',
  ],
};

export default function GoogleDBMCompnent() {
  return <GoogleDBM {...dbmParams} />;
}
