import React from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { denormalizeData } from 'core/utils/api';
import { topicsQuery } from 'core/queries/topics';

import TopicPages from 'core/components/TopicPages';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

const GET_PROPS = {
  limit: 1,
  fields: 'headline,link',
  topic_type: 'product',
  with_filtered_count: 1,
};

const DEFAULT_IMPORT_OBJECT = {
  attributes: {
    headline: '',
    link: '',
  },
};

export default function SiteTopicPages({ content }) {
  const published = content?.attributes.published_at;

  const { data: topicBefore, isLoading: topicBeforeIsLoading } = useRequest(
    topicsQuery({
      sort: '-published_at',
      published_before: published,
      renderError: () => DEFAULT_IMPORT_OBJECT,
      ...GET_PROPS,
    }, {
      enabled: content?.attributes?.topic_type === 'product',
      select: data => {
        const denormalizedData = denormalizeData(data)?.[0];

        return ({
          headline: denormalizedData?.attributes?.headline || '',
          link: denormalizedData?.attributes?.link || '',
          meta: data?.meta,
        });
      },
    }),
  );

  const { data: topicAfter, isLoading: topicAfterIsLoading } = useRequest(
    topicsQuery({
      sort: 'published_at',
      published_after: published,
      renderError: () => DEFAULT_IMPORT_OBJECT,
      ...GET_PROPS,
    }, {
      enabled: content?.attributes?.topic_type === 'product',
      select: data => {
        const denormalizedData = denormalizeData(data)?.[0];

        return ({
          headline: denormalizedData?.attributes?.headline || '',
          link: denormalizedData?.attributes?.link || '',
          meta: data?.meta,
        });
      },
    }),
  );

  if (topicBeforeIsLoading || topicAfterIsLoading) return null;

  const {
    headline: headlineBefore,
    link: linkBefore,
    meta: metaBefore,
  } = topicBefore;

  const {
    headline: headlineAfter,
    link: linkAfter,
    meta: metaAfter,
  } = topicAfter;

  const count = metaBefore.filtered_count + 1 + metaAfter.filtered_count;
  const active = count - metaAfter.filtered_count;

  function linkConstructor(current) {
    return current < active ? linkBefore : linkAfter;
  }

  return (
    <TopicPages
      active={active}
      total={count}
      linkConstructor={linkConstructor}
      prevTitle={<MarkdownWrapper inline>{headlineBefore}</MarkdownWrapper>}
      nextTitle={<MarkdownWrapper inline>{headlineAfter}</MarkdownWrapper>}
    />
  );
}

SiteTopicPages.propTypes = {
  content: PropTypes.object,
};
