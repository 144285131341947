import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import relationshipsPropTypes, {
  imagePropTypes,
  rubricPropTypes,
} from 'core/utils/prop-types/relationships';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { dateFormat } from 'core/utils/dates';
import resolveRelationships from 'core/utils/relationships';
import cutDown from 'core/utils/cut-down';

import styles from './index.styl';

const requiredPayloadImports = [
  'image',
  'rubric',
  'search_result',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'announce',
  'published_at',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  search_result: {
    attributes: {
      title: '',
      body: '',
    },
  },
});


function Card7(props) {
  const {
    content,
    breakpoint,
    isDesktop,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    announce,
    list_headline: listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    search_result: {
      title: searchTitle,
      body: searchBody,
    },
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          color ${theme.colors.primary}

          &:visited
            color ${theme.colors.primary}

        .time
          font-family ${theme.fonts.display}
          color ${theme.colors.hint}
      `}</style>
    </scope>
  );

  const banner = (
    <div
      className={cx(
        styles['card-content_block'],
        styles['card-banner'],
      )}
    >
      <div
        className={cx(
          styles['card-banner_block'],
          styles['card-time'],
          scoped.wrapClassNames('time'),
        )}
      >
        {dateFormat(publishedAt, 'd MMMM y')}
      </div>
    </div>
  );

  return (
    <div className={cx(
      styles.card,
      styles[`card--layout-${breakpoint}`],
    )}
    >
      <style jsx>{`
        .title
          font-family ${theme.fonts.display}

          :global(b)
            background-color ${theme.colors.accent}

        .headline
          font-family ${theme.fonts.text}
          color ${theme.colors.primary}

          :global(b)
            background-color ${theme.colors.accent}
      `}</style>

      <Link
        to={link}
        type='secondary'
        className={cx(
          styles['card-link'],
          scoped.wrapClassNames('link'),
        )}
        data-qa='card7'
      >
        <div className={styles['card-top_banner']}>
          {banner}
        </div>
        <div className={styles['card-content_wrapper']}>
          <div className={styles['card-content']}>
            <div className={styles['card-inner_banner']}>
              {banner}
            </div>
            <div
              className={cx([
                styles['card-content_block'],
                styles['card-title'],
                scoped.wrapClassNames('title'),
              ])}
            >
              <MarkdownWrapper inline>
                {searchTitle || listHeadline || headline}
              </MarkdownWrapper>
            </div>
            <div
              className={cx(
                styles['card-content_block'],
                styles['card-headline'],
                'headline',
              )}
              data-qa='card7-headline'
            >
              <MarkdownWrapper inline>
                {searchBody || cutDown(announce)}
              </MarkdownWrapper>
            </div>
          </div>
          <div className={styles['card-image']} data-qa='card7-image'>
            <Image
              src={cover}
              previewSrc={previewCover}
              position='50% 0'
              maxWidth={isDesktop ? 125 : 70}
            />
          </div>
        </div>
      </Link>
      <scoped.styles />
    </div>
  );
}

Card7.propTypes = {
  content: relationshipsPropTypes({
    image: imagePropTypes(),
    rubric: rubricPropTypes(),
  }),
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

const Card = cardHOC(Card7);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
